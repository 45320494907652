import request from '../_api/request'

const state = {
  customer: {
    data: [],
    total: 0
  }
}

const mutations = {
  SET_CUSTOMER (state, v) {
    state.customer.data = v.data || []
    state.customer.total = parseInt(v.total) || 0
  }
}

const actions = {
  async CUSTOMER_SEARCH (context, query) {
    return request.get('user/customer' + (query || '')).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async CUSTOMER_GET (context, query) {
    return request.get('user/customer/search' + (query || '')).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async CUSTOMER_PROCESS_BY_ADMIN (context, params) {
    return request.post('user/customer', params).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async CUSTOMER_PROCESS (context, params) {
    return request.post('user/customer/process', params).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async CUSTOMER_ADDRESS (context, cid) {
    return request.get('user/customer/address?cid=' + (cid || 0)).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async CUSTOMER_ADDRESS_PROCESS (context, params) {
    return await request.post('/user/address', params)
      .then(res => res)
      .catch((e) => {
        return {
          status: false,
          data: e
        }
      })
  },
  async CUSTOMER_ADDRESS_DELETE (context, aid) {
    return await request.delete('/user/customer/address/' + aid)
      .then(res => res)
      .catch((e) => {
        return {
          status: false,
          data: e
        }
      })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
