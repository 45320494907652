import request from '../_api/request'

const state = {
}

const mutations = {
}

const actions = {
  async BOOKING_GET (context, query) {
    // let page = 0
    // let limit = parseInt(query.limit) || 25
    // let q = ''
    // let status = undefined
    // let hotel = 0
    // let room = 0
    // let customer = 0
    // let sort = 'b.id,asc'
    // const fromTo: any = { from: (query.from || ''), to: (query.to || '') }
    return request.get('booking' + (query || '')).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async BOOKING_PROCESS (context, params) {
    return request.post('booking', params).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async BOOKING_SCHEDULE_PROCESS (context, params) {
    return request.post('booking/schedule/' + params.hotel, params).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async PAYMENT_PROCESS (context, params) {
    return request.post('booking/pay-at-hotel/' + params.hotel, params.params).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
