import request from '../_api/request'

const state = {
  rooms: [],
  banner: []
}

const mutations = {
  SET_ROOMS (state, v) {
    state.rooms = v || []
  },
  SET_ROOM_TABLE (state, v) {
    const index = state.rooms.findIndex(r => parseInt(r.id) === v.id)
    if (index > -1) {
      state.rooms[index].showTable = v.show
    }
  }
}

const actions = {
  async ROOMS_GET (context, query) {
    context.commit('SET_ROOMS', [])
    return request.get('store/room' + (query || '')).then((res) => {
      if (res.status) {
        const d = res.data.data.map(r => {
          r.showTable = false
          return r
        })
        context.commit('SET_ROOMS', d || [])
      } else {
        context.commit('SET_ROOMS', [])
      }
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async ROOM_PROCESS (context, params) {
    return request.post('store/room', params).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async ROOMS_LIST_GET (context, params) {
    return request.get('store/room/' + params.room + '/room-list' + (params.q || '')).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async ROOMS_LIST_PROCESS (context, params) {
    return request.post('store/room/' + params.room + '/room-list', params).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  // http://localhost:8011/booking/schedule/1?from=2022-07-10&to=2022-07-19
  async ROOM_SCHEDULES (context, query) {
    return request.get('booking/schedule' + (query || '')).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async ROOM_GET_DETAIL (context, roomID) {
    return await request.get('store/room' + roomID)
      .then((res) => {
        return res
      })
      .catch((err) => {
        return {
          status: false,
          data: err
        }
      })
  },
  async PRICE_BY_DATE_GET (context, query) {
    return request.get('store/price-by-date' + (query || '')).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async PRICE_BY_DATE_PROCESS (context, params) {
    return request.post('store/price-by-date', params).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async PRICE_BY_DATE_BULK_PROCESS (context, params) {
    return request.post('store/price-by-date-bulk', params).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  },
  async PRICE_BY_DATE_DELETE (context, params) {
    return request.delete('store/price-by-date/' + params.hotel + '/' + params.room + '/' + params.id).then((res) => {
      return res
    }).catch((e) => {
      return {
        status: false,
        data: e
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
